import React, { useEffect, useRef, useState } from 'react'
import CloneVoiceModal from './CloneVoiceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getChapter, getLevel, getMetaDataCSV, getRecordings, getRecordingsPage, setLevel } from '../../redux/audio/action';
import VoiceSlider from './VoiceSlider';
import { uploadCSV } from '../../redux/uploadFile/action';
import CreateRecordingModal from './CreateRecordingModal';
import ChapterLoader from '../../components/ui/ChapterLoader';
import VoicesWithPagination from './VoicesWithPagination';

const MyVoices = () => {

    const dispatch = useDispatch();
    // Apis Data
 

    const recordings = useSelector((state) => state?.audioReducer?.getRecordings?.data)
    const metaDataCSV=useSelector((state) => state?.audioReducer?.getMetaDataCSV?.data)
    const Levels = useSelector((state) => state?.audioReducer?.getLevel?.data)
    const chapters = useSelector((state) => state?.audioReducer?.getChapter?.data)

    // Apis loadings
    const recordingLoading = useSelector((state) => state?.audioReducer?.getRecordings?.loading)
    const chapterLoading = useSelector((state) => state?.audioReducer?.getChapter?.loading)

    
    const initial = useSelector((state) => state?.audioReducer?.level)

  
    // get it from url
    const { id } = useParams();

    const navigate = useNavigate();
  



    
    
    
    const [filterid,setFilterId]=useState(chapters?.emotion?.[0]?.id);
    const [filter, setFilter] = useState(chapters?.emotion?.[0]?.name);
    const [userLevel,setUserLevel]=useState();
    const [userLevelforCSV,setUserLevelforCSV]=useState();
    const [reqLevelEmotion, setReqLevelEmotion] = useState([])
    const [reqLevelGeneral, setReqLevelGeneral] = useState([])
    const [reqLevelEmotionForCSV, setReqLevelEmotionForCSV] = useState([])
    const [reqLevelGeneralForCSV, setReqLevelGeneralForCSV] = useState([])
    const [addVoices, setAddVoices] = useState();
    const [clone, setClone] = useState(false)
    useEffect(() => {
        if (!chapters) {
            if (id !== null) {
                dispatch(getChapter(id));
            }
        }
        setFilter(chapters?.emotion?.[0]?.name)
        setFilterId(chapters?.emotion?.[0]?.id)
    
        setAddVoices(chapters?.emotion?.[0]?.user_added_prompts)
    }, [chapters])


    console.log("addVoices",addVoices)

    useEffect(() => {
        console.log("initial",initial);
  setUserLevelforCSV(initial>0?initial-1:initial)
        setUserLevel(initial);
      }, [initial])

      console.log("userLevelforCSV after initial setup",userLevelforCSV);
      console.log("userLevelforCSV after initial setup",userLevel);




    useEffect(() => {
        if (Levels && Levels.length > 0) {
            // Check if userLevel is within the bounds of the Levels array
            if (userLevel >= 0 && userLevel < Levels.length) {
                const { level, id, ...filteredLevels } = Levels[userLevel];
    
                // Define the desired order
                const desiredOrder = [
                    "Sadness",
                    "Joy",
                    "Calm",
                    "Anger",
                    "Surprise",
                    "Neutral",
                    "Amused",
                    "Confident",
                    "Disgust",
                    "Empathetic",
                    "Fear",
                    "General",
                    "Chat",
                    "Customer Service"
                ];
    
                // Create an array of values in the desired order
                const orderedValuesArray = desiredOrder
                    .map(key => filteredLevels[key])
                    .filter(value => value !== undefined);
    
                // Split into reqLevelEmotion and reqLevelGeneral
                const reqLevelEmotion = orderedValuesArray.slice(0, 11); // First 11 values
                const reqLevelGeneral = orderedValuesArray.slice(11); // Remaining values
    
                setReqLevelEmotion(reqLevelEmotion); // Update state with the first 11 values
                setReqLevelGeneral(reqLevelGeneral); // Update state with the remaining values
            }
        } else {
            dispatch(getLevel()); // Fetch levels if not available
        }
    }, [Levels, userLevel]);
    
    useEffect(() => {
        if (Levels && Levels.length > 0) {
            // Check if userLevelforCSV is within the bounds of the Levels array
            if (userLevelforCSV >= 0 && userLevelforCSV < Levels.length) {
                const { level, id, ...filteredLevels } = Levels[userLevelforCSV];
    
                // Define the desired order
                const desiredOrder = [
                    "Sadness",
                    "Joy",
                    "Calm",
                    "Anger",
                    "Surprise",
                    "Neutral",
                    "Amused",
                    "Confident",
                    "Disgust",
                    "Empathetic",
                    "Fear",
                    "General",
                    "Chat",
                    "Customer Service"
                ];
    
                // Create an array of values in the desired order
                const orderedValuesArray = desiredOrder
                    .map(key => filteredLevels[key])
                    .filter(value => value !== undefined);
    
                // Split into reqLevelEmotion and reqLevelGeneral
                const reqLevelEmotion = orderedValuesArray.slice(0, 11); // First 11 values
                const reqLevelGeneral = orderedValuesArray.slice(11); // Remaining values
    
                // Update state with the first 11 values
                setReqLevelEmotionForCSV(reqLevelEmotion); 
                // Update state with the remaining values
                setReqLevelGeneralForCSV(reqLevelGeneral);
            }
        } else {
            dispatch(getLevel()); // Fetch levels if not available
        }
    }, [Levels, userLevelforCSV]);


    // use Effect for chapter get api
    const [meta, setMeta] = useState({
        language_id:id,
        chapters:[]
    });
    useEffect(() => {
        // Create a new array of emotion data
        const newEmotionData = chapters?.emotion?.map((item, index) => ({
            chapter_id: item.id,
            audio: reqLevelEmotion[index],
        })) || []; // Default to empty array if undefined
    
        const newGeneralData = chapters?.general?.map((item, index) => ({
            chapter_id: item.id,
            audio: reqLevelGeneral[index],
        })) || []; // Default to empty array if undefined
    
        // Merge the two arrays
        const mergedData = [...newEmotionData, ...newGeneralData];
    
        // Update the state with the merged data in meta.chapters
        setMeta((prevMeta) => ({
            ...prevMeta,
            chapters: mergedData,
        }));
    }, [chapters, reqLevelEmotion, reqLevelGeneral]);
    console.log("Meta after merge",meta)

    useEffect(() => {
        if (id !== null) {
            dispatch(getChapter(id));
            dispatch(getRecordings(id));
        }
    }, [id])

    // Unknow action
    const getMetaData=()=>{
            dispatch(getMetaDataCSV(meta))
    
    }
    // Use Ef
    useEffect(() => {
        if(metaDataCSV){
            handleExportCSV()
        }
    }, [metaDataCSV])
    const handleExportCSV = () => {
        // setUserLevel(userLevel+1)
        // Ensure metaDataCSV is defined and not null/undefined
        if (!metaDataCSV) return;
        // Convert filtered recordings to CSV format
        const csvContent =
            metaDataCSV.map(chapter => {
                // Append chapter_id_fk to the audio filename
                const audioWithChapter = `${chapter.chapter_id_fk}/${chapter.audio}`;
                return `${audioWithChapter}|${chapter.prompt.replace(/"/g, '""')}`;
            }).join("\n");
        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // setCSV_data({ ...CSV_data, csv: blob,language_id:id });
        dispatch(uploadCSV({csv:blob,language_id:id}))
    };
    useEffect(() => {
        console.log("useEffect is running");
        console.log("reqLevelEmotionForCSV", reqLevelEmotionForCSV);
        console.log("all data", chapters?.emotion, reqLevelEmotion, reqLevelGeneralForCSV, reqLevelEmotionForCSV, userLevel, userLevelforCSV);
    
        // Check if chapters?.emotion exists and has length greater than 0
        if (chapters?.emotion?.length > 0 && reqLevelEmotion?.length > 0 && reqLevelEmotionForCSV?.length > 0 && reqLevelGeneralForCSV?.length > 0) {
            console.log("Entering conditions for prompts sufficiency check");
    
            const allEmotionPromptsSufficient = chapters.emotion.every((item, index) => 
                Number(item.user_added_prompts) >= Number(reqLevelEmotionForCSV[index])
            );
    
            const allGeneralPromptsSufficient = chapters.general.every((item, index) => 
                Number(item.user_added_prompts) >= Number(reqLevelGeneralForCSV[index]) // Fixed this to use reqLevelGeneralForCSV
            );
    
            console.log("Checking true values:", allEmotionPromptsSufficient, allGeneralPromptsSufficient);
    
            if (allGeneralPromptsSufficient && allEmotionPromptsSufficient) {
                // Increment user level logic
                dispatch(setLevel(1));
                setClone(allEmotionPromptsSufficient && allGeneralPromptsSufficient);
            }
        } else {
            console.warn("One of the required conditions was not met");
        }
    }, [reqLevelGeneralForCSV, reqLevelEmotionForCSV, userLevel, userLevelforCSV, reqLevelEmotion, chapters]);
    



  

    console.log("clone>>>>>>>>>>>>>>>>>>>>>",clone)
    console.log(userLevel);
    console.log(userLevelforCSV)
    return (
        <>
        
            {/* Progress bar large on top */}
            <hr />
            {
            <>
            <h1 className='text-lg font-semibold mt-2'>My Level</h1>
            <div className='w-full  p-5 rounded-xl border flex justify-center items-center'>
            <ol className="flex items-center w-full">
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[0, 1, 2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[0, 1, 2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[0, 1, 2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                0
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[1, 2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[1, 2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[1, 2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                1
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                2
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1`}>
        <div className={`flex items-center justify-center w-10 h-10 ${userLevel === 3 ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${userLevel === 3 ? "text-green-500" : "text-gray-300"} font-bold`}>
                3
            </h1>
        </div>
    </li>
</ol>
            </div>
            </>
            }

            {/* Main section where chapter and voices listed */}
            { 
            <div className='flex flex-col md:flex md:flex-row md:gap-x-2 '>

                {
                    chapterLoading?
                    <div className='w-full bg-gray-100 rounded-lg md:w-1/4 p-2'>
                       <ChapterLoader/>
                       <ChapterLoader/>
                       <ChapterLoader/>
                    </div>
                     :
                     <div className='w-full bg-colorPrimary rounded-lg bg-opacity-5 md:w-1/4'>
                                <div className=' text-white px-2 py-2'>
                                {/* add chapter here */}
                    <>
                        <div className='mb- overflow-hidden'>
                            <h2 className='text-lg font-semibold text-colorPrimary'>Select Expression</h2>
                            <div className='flex md:flex-col gap-2 items-center w-screen md:w-full overflow-x-auto '>
                                {
                                    <>
    {chapters?.emotion?.map((item,index) =>
    index >= 0 && index < reqLevelEmotion.length && Number(reqLevelEmotion[index]) !== undefined && 
    <button onClick={() => {setFilter(item?.name); setFilterId(item?.id);setAddVoices(item?.user_added_prompts)}} className={`border text-black w-full p-1 rounded-lg ${filter === item?.name && " bg-blue-600 bg-opacity-5  border-colorPrimary"} `}>
        <div className='flex gap-2 items-center my-2 justify-between'>
        <div className='flex items-center gap-2'>
        <svg className={` ${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index]))===Number(reqLevelEmotion[index])?"bg-green-00 text-green-900":"bg-gray-400"} rounded-full w-5 h-5`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z" fill={` ${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?(Number(item?.user_added_prompts)):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?"rgb(34 197 94)":"rgb(209 213 219)"}`}></path> </g></svg>

<h1 className=''>
{item?.name}
</h1>
        </div>
        <h1 className={`${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?" bg-green-600 text-white": "bg-yellow-200 text-yellow-600"} py-1 px-3 rounded-full text-sm`}>
            {
                ((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?"Completed":"Remaining"
            }
        </h1>
        </div>
        
        <div class="w-full bg-white rounded-full mr-2  ">
                                                    <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"  style={{ width: `${Math.max(20,Math.min(((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index])?(item?.user_added_prompts):reqLevelEmotion[index])/ reqLevelEmotion[index]) * 100, 100))}%` }}>
                                                    {Math.floor(((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index])?item?.user_added_prompts:Number(reqLevelEmotion[index]))/ Number(reqLevelEmotion[index])) * 100)}%
                                                        </div>
                                                    </div>
        <div className='flex justify-end items-center text-sm mt-2'>
            {/* {(item?.user_added_prompts<reqLevelEmotion[index])?(item?.user_added_prompts):reqLevelEmotion[index]}/{reqLevelEmotion[index]} */}
            {index >= 0 && index < reqLevelEmotion?.length && Number(reqLevelEmotion[index]) !== undefined && (
    <div>
        {console.log("User Prompts:", item?.user_added_prompts, "Req Level:", Number(reqLevelEmotion[index]))}
        {Number(item?.user_added_prompts) <= Number(reqLevelEmotion[index]) 
            ? item?.user_added_prompts 
            : Number(reqLevelEmotion[index])} 
        / {Number(reqLevelEmotion[index])}
    </div>
)}


        </div>
    </button>
    )}
    {chapters?.general?.map((item,index) =>
    index >= 0 && index < reqLevelGeneral?.length && Number(reqLevelGeneral[index]) !== undefined && 
    <button onClick={() => {setFilter(item?.name); setFilterId(item?.id);setAddVoices(item?.user_added_prompts)}} className={`border text-black w-full p-1 rounded-lg ${filter === item?.name && " bg-blue-600 bg-opacity-5  border-colorPrimary"} `}>
    <div className='flex gap-2 items-center my-2 justify-between'>
    <div className='flex items-center gap-2'>
    <svg className={` ${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index]))===Number(reqLevelGeneral[index])?"bg-green-00 text-green-900":"bg-gray-400"} rounded-full w-5 h-5`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z" fill={` ${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?(Number(item?.user_added_prompts)):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?"rgb(34 197 94)":"rgb(209 213 219)"}`}></path> </g></svg>

<h1 className=''>
{item?.name}
</h1>
    </div>
    <h1 className={`${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?" bg-green-600 text-white": "bg-yellow-200 text-yellow-600"} py-1 px-3 rounded-full text-sm`}>
        {
            ((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?"Completed":"Remaining"
        }
    </h1>
    </div>
    
    <div class="w-full bg-white rounded-full mr-2  ">
                                                <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"  style={{ width: `${Math.max(20,Math.min(((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index])?(item?.user_added_prompts):reqLevelGeneral[index])/ reqLevelGeneral[index]) * 100, 100))}%` }}>
                                                {Math.floor(((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index])?item?.user_added_prompts:Number(reqLevelGeneral[index]))/ Number(reqLevelGeneral[index])) * 100)}%
                                                    </div>
                                                </div>
    <div className='flex justify-end items-center text-sm mt-2'>
        {/* {(item?.user_added_prompts<reqLevelGeneral[index])?(item?.user_added_prompts):reqLevelGeneral[index]}/{reqLevelGeneral[index]} */}
        {index >= 0 && index < reqLevelGeneral.length && Number(reqLevelGeneral[index]) !== undefined && (
<div>
    {console.log("User Prompts:", item?.user_added_prompts, "Req Level:", Number(reqLevelGeneral[index]))}
    {Number(item?.user_added_prompts) <= Number(reqLevelGeneral[index]) 
        ? item?.user_added_prompts 
        : Number(reqLevelGeneral[index])} 
    / {Number(reqLevelGeneral[index])}
</div>
)}
    </div>
</button>
)}
                                    </>    
                                }
                            </div>
                        </div>

                    </>
                                </div>
                            </div>
                }
                
                    <div className='w-full md:w-3/4 min-w-3/4'>

                            
                        {recordingLoading?
<>
<div className="animate-pulse bg-gray-200 rounded-lg p-2">
    <div className='my-1 bg-gray-300 h-4 rounded w-fll'></div>
    <div className="w-full bg-white rounded-full mr-2 h-2">
        <div className="bg-gray-300 rounded-full h-2" style={{ width: '100%' }}></div>
    </div>
    <div className='my-2 bg-gray-300 h-4 rounded w-1/2'></div>
</div>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 mt-4">
 
        <div className="bg-gray-200 rounded-lg p-4 animate-pulse">
            <div className="h-24 bg-gray-300 rounded mb-2"></div> {/* Placeholder for audio thumbnail */}
            <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
            <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
        </div>
        <div className="bg-gray-200 rounded-lg p-4 animate-pulse">
            <div className="h-24 bg-gray-300 rounded mb-2"></div> {/* Placeholder for audio thumbnail */}
            <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
            <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
        </div>
        <div className="bg-gray-200 rounded-lg p-4 animate-pulse">
            <div className="h-24 bg-gray-300 rounded mb-2"></div> {/* Placeholder for audio thumbnail */}
            <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
            <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
                            </div>

                            </div>
                            </>  :recordings && recordings?.chapters?.length > 0 &&
                            <>
                                {!chapterLoading &&
                                    recordings?.chapters
                                        ?.filter(chapter => chapter?.name?.toLowerCase() === (filter && filter?.toLowerCase()))
                                        ?.map((chapter) => (
                                            <div key={chapter.chapter_id}>
                                                {chapter?.topics?.length > 0 ?                                   
                                                    <div className='w-full flex justify-between items-end my-2'>
                                                        <h1>
                                                        My Voices
                                                        </h1>
                                                        <div className='sm:flex sm:flex-row flex flex-col '>
                                                        <button onClick={() => navigate(`recording/${filter}/${filterid}`)} className='flex items-center justify-center mx-2 gap-1.5 border border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                           <span>Add more voices</span>
                                                        </button>
                                                        {
                                                            clone?
                                                            <CloneVoiceModal handleExportCSV={handleExportCSV} getMetaData={getMetaData} />
                                                        :<button className='bg-rose-600 text-sm px-2 py-1 animate-pulse  text-white rounded sm:mt-0 mt-2'>{`Achieve level 1 to generate clone`}</button>
                                                        }
                                                        
                                                       {/* <button onClick={handleExportCSV}>Export to CSV</button> */}
                                                         
                                                   </div>
                                                        
                                                    </div>  
                                                 :
                                                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-y-4 md:gap-y-0">
                                                        <div>
                                                            <h1 className='font-semibold text-lg'>My Voices</h1>
                                                        </div>
                                                        {/* <div className='sm:flex sm:flex-row flex flex-col'>
                                                            <button onClick={() => navigate('recording')} className='flex items-center justify-center mx-2 gap-1.5 border bg-colorPrimary text-white border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                                <span>Add Voices</span>
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                }
                                                <div>
                                                    <div className="mt-3 flex justify-between">
                                                        <p className='font-semibold'>{chapter?.name}</p>
                                                        {/* <p className={`font-semibol ${chapter?.completed_topics>=topicPerChapter?"text-green-600":"text-yellow-500"} font-bold`}>{chapter?.completed_topics>topicPerChapter?topicPerChapter:chapter?.completed_topics}/{topicPerChapter}</p> */}
                                                    </div>
                                                    {chapter?.topics?.length > 0 ? (    
                                                        // <VoiceSlider chapter={chapter} />
                                                        <VoicesWithPagination id={id} filterId={filterid} addVoices={addVoices}/>
                                                    )
                                                        : <div className="flex flex-col gap-5 text-sm  mt-8 items-center justify-center">
                                                        <h6 className='font-semibold'>Currently you have not any voices</h6>
                                                        <button onClick={() => navigate(`recording/${filter}/${filterid}`)} className='flex items-center justify-center mx-2 gap-1.5 border border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                           <span>Add voices</span>
                                                        </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                }
                            </>
                        }
                    </div>
                    </div>

            }
     
        </>
       
    )
}

export default MyVoices