import React, { useEffect, useState } from 'react'
import RecordingAudio from './RecordingAudio'
import { useNavigate, useParams } from 'react-router';
import { getChapter, getChapterPrompt, getLevel, setChapterPrompt, setChapterPromptMessage, setLevel } from '../../redux/audio/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import loader from '../../assets/svgs/loader.svg'
import { Typewriter } from 'react-simple-typewriter';
import ChapterLoader from '../../components/ui/ChapterLoader';


const RecordingPage = ({expression}) => {
    const [addedPrompts, setAddedPrompts] = useState()
    const Levels = useSelector((state) => state?.audioReducer?.getLevel?.data)

    const [audioUrl, setAudioUrl] = useState("")
    const {id,filterprm,filterId}=useParams();
    const [data, setData] = useState({
        language_id:id,
        chapter_id:"",
        topic_id:"",
        prompt:"",
        audio:"",
    })
    const [recording, setRecording] = useState()
    
    const dispatch=useDispatch();
    const initialChapter=useSelector((state)=>state?.audioReducer?.getChapter?.data)
    const [chapters, setChapters] = useState(initialChapter)

    const prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.prompts)
    const promptLoading=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.loading)
    let remainingPrompt=JSON.parse(localStorage.getItem('prompts'))
    const response=useSelector((state)=>state?.audioReducer?.setChapterPrompt?.message)
    const loading=useSelector((state)=>state?.audioReducer?.setChapterPrompt?.loading)
    // const loading=true
    const total_prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.total_prompts_count)
    const added_prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.total_prompts_add_user)
    const chapterLoading = useSelector((state) => state?.audioReducer?.getChapter?.loading)
    const recordings = useSelector((state) => state?.audioReducer?.getRecordings?.data)
    const initial = useSelector((state) => state?.audioReducer?.level)
    const [userLevel,setUserLevel]=useState(initial);
    const [userLevelforCSV,setUserLevelforCSV]=useState(userLevel);
    const [reqLevelEmotionForCSV, setReqLevelEmotionForCSV] = useState([])
    const [reqLevelGeneralForCSV, setReqLevelGeneralForCSV] = useState([])

    const [incrementID, setIncrementID] = useState(null)

    const [filterID,setFilterId]=useState(chapters?.emotion?.[0]?.id);
const [totalVoices, setTotalVoices] = useState()
const [addedVoices, setAddedVoices] = useState(Number(chapters?.emotion[0]?.user_added_prompts)<=Number(totalVoices)?(chapters?.emotion[0]?.user_added_prompts):totalVoices);


    useEffect(() => {
        
      if(response){
        if(incrementID){
            increment(incrementID) 
        }
        console.log("response||||||||||||||||||||||||||||||||||",response)
        dispatch(setChapterPromptMessage());
        setAudioUrl(null);
        
        let pro= JSON.parse(localStorage.getItem('prompts'))
        pro.shift();
        setAddedPrompts(addedPrompts+1);
        localStorage.setItem('prompts',JSON.stringify(pro))
        console.log("asjdnbjsabdkdlksadn data after shift in response of abi",pro);
        setData({
            ...data,
            prompt:pro[0]?.prompt,
            topic_id:pro[0]?.id,
            chapter_id:pro[0]?.chapter_id

        })
      }
    }, [response])


    

    const [filter, setFilter] = useState(filterprm);
    useEffect(() => {
        if (!chapters) {
            if (id !== null) {
                dispatch(getChapter(id));
            }
        }
        // setFilter(filterprm)
        dispatch(getChapterPrompt(filterID))
        console.log("get chapters", chapters);
        if(chapters)
            {
                setAddedVoices(Number(chapters?.emotion[0]?.user_added_prompts)<=Number(totalVoices)?(chapters?.emotion[0]?.user_added_prompts):totalVoices)
            }        
    }, [chapters])

    useEffect(() => {
        setAddedVoices(
        Number(chapters?.emotion[0]?.user_added_prompts)<=Number(totalVoices)?(chapters?.emotion[0]?.user_added_prompts):totalVoices
        )
    }, [])    
    useEffect(() => {
        if(prompt){           
           localStorage.setItem('prompts',JSON.stringify(prompt))
           let pro=JSON.parse(localStorage.getItem('prompts'))
            setData({
                ...data,
                prompt:pro[0]?.prompt,
                topic_id:pro[0]?.id,
                chapter_id:pro[0]?.chapter_id

            })
            console.log("prompt........................:::::::::::::::::::::",prompt)
        }
    }, [prompt])

    console.log("Data:::::::::::::::::::::::::::::::::::::::::::",data)

    useEffect(() => {
       setAddedPrompts(added_prompt);
      }, [added_prompt])
    useEffect(() => {
      if(!chapters){
        if(id!==null){
            dispatch(getChapter(id));
        }
      }
      console.log("get chapters",chapters);
    }, [chapters]) 
    useEffect(() => {
          if(id!==null && chapters===null){
              dispatch(getChapter(id));
          }
          console.log("get chapters",chapters);
      }, [id])
    const navigate = useNavigate();
    const handleSubmit = () => {

        // console.log("id.............................:::::::::::>>>>>>",id)
        setAudioUrl(null);
        // navigate(-1)
        console.log("data:::::::::::::::::::::::::::::::::::",data)
        // console.log({audio:recording,prompt:prompt[0]?.prompt,id:id})
        dispatch(setChapterPrompt(data))  
    }
const getPrompts=(item)=>{
    setData({...data,chapter_id:item?.id})
    setFilter(item?.name)
    dispatch(getChapterPrompt(item?.id))
}
const [reqLevelEmotion, setReqLevelEmotion] = useState([])
const [reqLevelGeneral, setReqLevelGeneral] = useState([])

useEffect(() => {
    if (Levels&&Levels.length>0) {
        const { level, id, ...filteredLevels } = Levels[userLevel];

        // Define the desired order
        const desiredOrder = [
            "Sadness",
            "Joy",
            "Calm",
            "Anger",
            "Surprise",
            "Neutral",
            "Amused",
            "Confident",
            "Disgust",
            "Empathetic",
            "Fear",
            "General",
            "Chat",
            "Customer Service"
        ];

        // Create an array of values in the desired order
        const orderedValuesArray = desiredOrder?.map(key => filteredLevels[key])?.filter(value => value !== undefined);

        // Split into reqLevelEmotion and reqLevelGeneral
        const reqLevelEmotion = orderedValuesArray.slice(0, 11); // First 11 values
        const reqLevelGeneral = orderedValuesArray.slice(11); // Remaining values

        setReqLevelEmotion(reqLevelEmotion); // Update state with the first 11 values
        setReqLevelGeneral(reqLevelGeneral); // Update state with the remaining values
    } else {
        dispatch(getLevel()); // Fetch levels if not available
    }
}, [Levels, userLevel, dispatch]);


useEffect(() => {
  if(reqLevelEmotion!==undefined&&reqLevelEmotion?.length>0){
    setTotalVoices(reqLevelEmotion[0])
  }
}, [reqLevelEmotion,reqLevelGeneral]
)


useEffect(() => {
    console.log("initial",initial);
setUserLevelforCSV(initial>0?initial-1:initial)
    setUserLevel(initial);
  }, [initial])




useEffect(() => {
    console.log("useEffect is running");
    console.log("reqLevelEmotionForCSV", reqLevelEmotionForCSV);
    console.log("all data", chapters?.emotion, reqLevelEmotion, reqLevelGeneralForCSV, reqLevelEmotionForCSV, userLevel, userLevelforCSV);

    // Check if chapters?.emotion exists and has length greater than 0
    if (chapters?.emotion?.length > 0 && reqLevelEmotion?.length > 0 && reqLevelEmotionForCSV?.length > 0 && reqLevelGeneralForCSV?.length > 0) {
        console.log("Entering conditions for prompts sufficiency check");

        const allEmotionPromptsSufficient = chapters.emotion.every((item, index) => 
            Number(item.user_added_prompts) >= Number(reqLevelEmotionForCSV[index])
        );

        const allGeneralPromptsSufficient = chapters.general.every((item, index) => 
            Number(item.user_added_prompts) >= Number(reqLevelGeneralForCSV[index]) // Fixed this to use reqLevelGeneralForCSV
        );

        console.log("Checking true values:", allEmotionPromptsSufficient, allGeneralPromptsSufficient);

        if (allGeneralPromptsSufficient && allEmotionPromptsSufficient) {
            // Increment user level logic
            dispatch(setLevel(1));
        }
    } else {
        console.warn("One of the required conditions was not met");
    }
}, [reqLevelGeneralForCSV, reqLevelEmotionForCSV, userLevel, userLevelforCSV, reqLevelEmotion, chapters,response]);



// this code for increment purpose
const [type, setType] = useState("emotion")
const increment=(id)=>{
    setChapters((prevChapters) => {
        return {
            ...prevChapters,
            [type]: prevChapters[type].map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        user_added_prompts: Number(item?.user_added_prompts) + 1, // Increment by 1
                    };
                }
                return item;
            }),
        };
    });
    
}
  return (
        <div className="px-6 py-6 rounded-lg flex flex-col gap-6">
            <div>
                <div className='flex gap-1 items-center cursor-pointer'>
                <svg onClick={()=>navigate(-1)} class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
</svg>
                <h2 className='text-lg font-semibold'>Dipspik Learning Guide</h2>
                </div>
                <p className='text-gray-600 text-sm font-normal'>You are not familiar how to record audio, explore the tutorial guide</p>
            </div>
            {recordings && recordings?.chapters?.length > 0 &&
            <>
            <h1 className='text-lg font-semibold mt-2'>My Level</h1>
            <div className='w-full  p-5 rounded-xl border flex justify-center items-center'>
            <ol className="flex items-center w-full">
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[0, 1, 2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[0, 1, 2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[0, 1, 2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                0
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[1, 2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[1, 2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[1, 2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                1
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${[2, 3].includes(userLevel) ? "after:border-green-500" : "after:border-gray-100"} after:border-4 after:inline-block`}>
        <div className={`flex items-center justify-center w-10 h-10 ${[2, 3].includes(userLevel) ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${[2, 3].includes(userLevel) ? "text-green-500" : "text-gray-300"} font-bold`}>
                2
            </h1>
        </div>
    </li>
    <li className={`flex w-full items-center after:content-[''] after:w-full after:h-1`}>
        <div className={`flex items-center justify-center w-10 h-10 ${userLevel === 3 ? "bg-green-100" : "bg-gray-100"} rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <h1 className={`${userLevel === 3 ? "text-green-500" : "text-gray-300"} font-bold`}>
                3
            </h1>
        </div>
    </li>
</ol>
            </div>
            </>
            }
<div className='flex flex-col md:flex md:flex-row md:gap-x-2 '>
{
    chapterLoading?
    <div className='w-full bg-gray-100 rounded-lg md:w-1/4 p-2'>
       <ChapterLoader/>
       <ChapterLoader/>
       <ChapterLoader/>
    </div>
     :
     <div className='w-full bg-colorPrimary rounded-lg bg-opacity-5 md:w-1/4'>
                <div className=' text-white px-2 py-2'>
                {/* add chapter here */}
                <>
    {chapters?.emotion?.map((item,index) =>
    index >= 0 && index < reqLevelEmotion.length && Number(reqLevelEmotion[index]) !== undefined && 
    <button onClick={() => {setFilter(item?.name); setFilterId(item?.id);getPrompts(item)}} className={`border text-black w-full p-1 rounded-lg ${filter === item?.name && " bg-blue-600 bg-opacity-5  border-colorPrimary"} `}>
        <div className='flex gap-2 items-center my-2 justify-between'>
        <div className='flex items-center gap-2'>
        <svg className={` ${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index]))===Number(reqLevelEmotion[index])?"bg-green-00 text-green-900":"bg-gray-400"} rounded-full w-5 h-5`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z" fill={` ${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?(Number(item?.user_added_prompts)):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?"rgb(34 197 94)":"rgb(209 213 219)"}`}></path> </g></svg>

<h1 className=''>
{item?.name}
</h1>
        </div>
        <h1 className={`${((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?" bg-green-600 text-white": "bg-yellow-200 text-yellow-600"} py-1 px-3 rounded-full text-sm`}>
            {
                ((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index]))?Number(item?.user_added_prompts):Number(reqLevelEmotion[index])) ===Number(reqLevelEmotion[index])?"Completed":"Remaining"
            }
        </h1>
        </div>
        
        <div class="w-full bg-white rounded-full mr-2  ">
                                                    <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"  style={{ width: `${Math.max(20,Math.min(((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index])?(item?.user_added_prompts):reqLevelEmotion[index])/ reqLevelEmotion[index]) * 100, 100))}%` }}>
                                                    {Math.floor(((Number(item?.user_added_prompts)<=Number(reqLevelEmotion[index])?item?.user_added_prompts:Number(reqLevelEmotion[index]))/ Number(reqLevelEmotion[index])) * 100)}%
                                                        </div>
                                                    </div>
        <div className='flex justify-end items-center text-sm mt-2'>
            {/* {(item?.user_added_prompts<reqLevelEmotion[index])?(item?.user_added_prompts):reqLevelEmotion[index]}/{reqLevelEmotion[index]} */}
            {index >= 0 && index < reqLevelEmotion?.length && Number(reqLevelEmotion[index]) !== undefined && (
    <div>
        {console.log("User Prompts:", item?.user_added_prompts, "Req Level:", Number(reqLevelEmotion[index]))}
        {Number(item?.user_added_prompts) <= Number(reqLevelEmotion[index]) 
            ? item?.user_added_prompts 
            : Number(reqLevelEmotion[index])} 
        / {Number(reqLevelEmotion[index])}
    </div>
)}


        </div>
    </button>
    )}
    {chapters?.general?.map((item,index) =>
    index >= 0 && index < reqLevelGeneral?.length && Number(reqLevelGeneral[index]) !== undefined && 
    <button onClick={() => {setFilter(item?.name); setFilterId(item?.id);getPrompts(item)}} className={`border text-black w-full p-1 rounded-lg ${filter === item?.name && " bg-blue-600 bg-opacity-5  border-colorPrimary"} `}>
    <div className='flex gap-2 items-center my-2 justify-between'>
    <div className='flex items-center gap-2'>
    <svg className={` ${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index]))===Number(reqLevelGeneral[index])?"bg-green-00 text-green-900":"bg-gray-400"} rounded-full w-5 h-5`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z" fill={` ${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?(Number(item?.user_added_prompts)):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?"rgb(34 197 94)":"rgb(209 213 219)"}`}></path> </g></svg>

<h1 className=''>
{item?.name}
</h1>
    </div>
    <h1 className={`${((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?" bg-green-600 text-white": "bg-yellow-200 text-yellow-600"} py-1 px-3 rounded-full text-sm`}>
        {
            ((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index]))?Number(item?.user_added_prompts):Number(reqLevelGeneral[index])) ===Number(reqLevelGeneral[index])?"Completed":"Remaining"
        }
    </h1>
    </div>
    
    <div class="w-full bg-white rounded-full mr-2  ">
                                                <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"  style={{ width: `${Math.max(20,Math.min(((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index])?(item?.user_added_prompts):reqLevelGeneral[index])/ reqLevelGeneral[index]) * 100, 100))}%` }}>
                                                {Math.floor(((Number(item?.user_added_prompts)<=Number(reqLevelGeneral[index])?item?.user_added_prompts:Number(reqLevelGeneral[index]))/ Number(reqLevelGeneral[index])) * 100)}%
                                                    </div>
                                                </div>
    <div className='flex justify-end items-center text-sm mt-2'>
        {/* {(item?.user_added_prompts<reqLevelGeneral[index])?(item?.user_added_prompts):reqLevelGeneral[index]}/{reqLevelGeneral[index]} */}
        {index >= 0 && index < reqLevelGeneral.length && Number(reqLevelGeneral[index]) !== undefined && (
<div>
    {console.log("User Prompts:", item?.user_added_prompts, "Req Level:", Number(reqLevelGeneral[index]))}
    {Number(item?.user_added_prompts) <= Number(reqLevelGeneral[index]) 
        ? item?.user_added_prompts 
        : Number(reqLevelGeneral[index])} 
    / {Number(reqLevelGeneral[index])}
</div>
)}
    </div>
</button>
)}
                                    </>   
                </div>
            </div>
}

            <div className='w-full md:w-3/4 min-w-3/4'>

            {
                promptLoading?<div className="flex flex-col lg:flex-row sm:justify-between items-center bg-gray-100 rounded-xl mt-2 py-2 px-5 animate-pulse">
                <div className="w-full lg:w-full h-8 sm:h-10 flex flex-col gap-y-1 ">
                    <div className="h-full bg-gray-300 rounded-full" ></div>
                    <div className="h-full bg-gray-300 rounded-full w-1/2" ></div>
                    
                </div>
                <div className="w-full lg:w-1/2 h-full lg:h-20 lg:ml-4 flex items-center justify-center sm:justify-end">
                <div className="h-10 w-10 bg-gray-300 rounded-full mt-2"></div>
                   
                </div>
            </div>
            :
                (
                    (data)?
                    <div>
                        <div className='flex justify-between items-center w-full'>
                        <h3 className='font-semibold'>Prompt</h3>
                        {/* {
                            addedVoices!==null&&
                            <h3 className='font-semibold'>{(addedVoices<=totalVoices)?addedVoices:totalVoices}/{totalVoices}</h3>
                        } */}
                        

                        
                        
                        </div>
                
                <div className="flex flex-col lg:flex-row sm:justify-between items-center bg-gray-100 rounded-xl mt-2 py-4 px-5">
                    
                    <p className='text-lg text-justify'>
        <Typewriter
          words={[data?.prompt]}
          key={data?.prompt}
          loop={1}  // Change to `Infinity` for infinite looping
          typeSpeed={20}
          deleteSpeed={10}
          delaySpeed={300}
        />
      </p>

                    {/* <AudioRecorder/> */}
                    <RecordingAudio setData={setData} audioUrl={audioUrl} setAudioUrl={setAudioUrl} data={data} />
                </div>
                {
                    audioUrl&&
                    <div id="audios" className='my-4 flex flex-row gap-3'>
                    <audio controls src={audioUrl}></audio>
                    {/* <button className='bg-gray-200 px-4 text-sms rounded-full hover:bg-gray-300' >
                        Delete
                    </button> */}
                    </div>
                }
                
                <div className="mb-2 pt-3 flex gap-3 justify-end mt-4">
                    {
                         loading?<img className='w-9' src={loader} alt="" />:
                         ( audioUrl&&
                            <>
                            <button
                          type="button"
                          // onClick={handleSubmit}
                          onClick={()=>{setAudioUrl(null);setData({...data, audio:null})}}
                          className="bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      >
                          Cancel
                      </button>
                      
                         
                          <button
                          type="button"
                          onClick={()=>{handleSubmit();setIncrementID(filterID)}}
                          className="relative text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      >Submit  
                      </button>
                           </>
                         )
                   
                    }
                   
                    

                </div>
                    </div>
                    :
                    <h1>There is no prompt available for this expression please select other</h1>
            )
            }
    </div>
        </div>      
        </div>
    )
}

export default RecordingPage