import {
  All_USER_GET,
  All_USER_GET_FAILURE,
  All_USER_GET_SUCCESS,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  OTP_VERIFICATION,
  OTP_VERIFICATION_FAILURE,
  OTP_VERIFICATION_SUCCESS,
  RESEND_OTP,
  RESEND_OTP_FAILURE,
  RESEND_OTP_RESET,
  RESEND_OTP_SUCCESS,
  SET_STATUS_NULL,
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SOCIAL_SIGNUP_LOGIN,
  SOCIAL_SIGNUP_LOGIN_FAILURE,
  SOCIAL_SIGNUP_LOGIN_SUCCESS,
  TESTING_AUTH_TOKEN,
  TESTING_AUTH_TOKEN_FAILURE,
  TESTING_AUTH_TOKEN_SUCCESS,
} from "../actonTypes";
const initial_state = {
  isAuthenticated:
    localStorage.getItem("authToken") &&
      localStorage.getItem("authToken") !== undefined
      ? true
      : false,
  login: {
    message: null,
    error: null,
    loading: false,
    status: null,
    data: null,
  },
  signup: {
    message: null,
    error: null,
    loading: false,
    status: null,
    data: null
  },
  allUser: {
    message: null,
    error: null,
    loading: false,
    status: null,
    data: null
  },
  verification: {

    message: null,
    error: null,
    loading: false,
    status: null,
  },
  testingToken: {
    message: null,
    error: null,
    loading: false,
    status: null,
    data:null
  },
  resendOTP: {

    message: null,
    error: null,
    loading: false,
    status: null,
  },
  socialSignupLogin: {

    message: null,
    error: null,
    loading: false,
    status: null,
  },
};
const authReducer = (state = initial_state, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        login: {
          ...state.login,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: payload.message,
        },
      };
    
      case SIGNUP:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true,
        },
      };
    case SIGNUP_SUCCESS:

    localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        signup: {
          ...state.signup,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };

    

      // return {
      //   ...state,
      //   signup: {
      //     ...state.signup,
      //     loading: false,
      //     message: payload.message,
      //     status: payload.status,
      //     data: payload.data
      //   },
      // };
    case SIGNUP_FAILURE:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          error: payload.message,
        },
      };

    case OTP_VERIFICATION:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: true,
        },
      };
    case OTP_VERIFICATION_SUCCESS:
      localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        verification: {
          ...state.verification,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };
    
      case OTP_VERIFICATION_FAILURE:

      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          error: payload.message,
        },
      };
      case TESTING_AUTH_TOKEN:
      return {
        ...state,
        testingToken: {
          ...state.testingToken,
          loading: true,
        },
      };
    case TESTING_AUTH_TOKEN_SUCCESS:
      localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        testingToken: {
          ...state.testingToken,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };
    case TESTING_AUTH_TOKEN_FAILURE:

      return {
        ...state,
        testingToken: {
          ...state.testingToken,
          loading: false,
          error: payload.message,
        },
      };


      case All_USER_GET:
      console.log("reducer is hitting")  
      return {
          ...state,
          allUser: {
            ...state.allUser,
            loading: true,
          },
        };
      case All_USER_GET_SUCCESS:
        return {
          ...state,
          allUser: {
            ...state.allUser,
            loading: false,
            message: payload.message,
            status: payload.status,
            data: payload.data
          },
        };
      case All_USER_GET_FAILURE:
        return {
          ...state,
          allUser: {
            ...state.allUser,
            loading: false,
            error: payload.message,
          },
        };
      
    case SOCIAL_SIGNUP_LOGIN:
      return {
        ...state,
        socialSignupLogin: {
          ...state.socialSignupLogin,
          loading: true,
        },
      };
    case SOCIAL_SIGNUP_LOGIN_SUCCESS:
      localStorage.setItem("authToken", payload.token);
      return {
        ...state,
        isAuthenticated: true,
        socialSignupLogin: {
          ...state.socialSignupLogin,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };
    case SOCIAL_SIGNUP_LOGIN_FAILURE:
      return {
        ...state,
        socialSignupLogin: {
          ...state.socialSignupLogin,
          loading: false,
          error: payload.message,
        },
      };
      
      case SET_STATUS_NULL:
        return {
          ...state,
          signup: {
            ...state.signup,
            status: null,
          },
          login: {
            ...state.login,
            status: null,
          },
        };





    case RESEND_OTP:
      return {
        ...state,
        resendOTP: {
          ...state.resendOTP,
          loading: true,
        },
      };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendOTP: {
          ...state.resendOTP,
          loading: false,
          message: payload.message,
          status: payload.status,
        },
      };
    case RESEND_OTP_FAILURE:
      return {
        ...state,
        resendOTP: {
          ...state.resendOTP,
          loading: false,
          status: payload.status,
        },
      };

    case RESEND_OTP_RESET:
      return {
        ...state,
        resendOTP: {
          ...state.resendOTP,
          loading: false,
          status: null,
        },
      };
    case LOGOUT:
      localStorage.removeItem("authToken");
      localStorage.removeItem("email");
      // Remove other items if needed
      return {
        ...state,
        isAuthenticated: false,
        login: {
          ...state.login,
          status: null,
        },
        signup: {
          ...state.signup,
          status: null,
        },
        verification: {
          ...state.verification,
          status: null,
        },
      };
    default:
      return state;
  }
};
export default authReducer;
