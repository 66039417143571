import { GET_USER, GET_USER_FAILURE, GET_USER_SUCCESS, UPDATE_PASSWORD, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from "../actonTypes";
// import { updatePassword } from "./action";
const initial_state = {
    getUser: {
        data: "",
        message: "",
        error: "",
        loading: false,
    },
    updateUser: {
        data: "",
        message: "",
        error: "",
        loading: false,
    },
     updatePassword: {
        data: "",
        message: "",
        error: "",
        loading: false,
    },
}
const userReducer = (state = initial_state, { type, payload }) => {
    console.log("payload", payload)
    switch (type) {
        case GET_USER:
            return {
                ...state,
                getUser: {
                    ...state.getUser,
                    loading: true,
                }
            };
        case GET_USER_SUCCESS:
            console.log("GET_USER_DETAILS_SUCCESS", payload.data)
            return {
                ...state,
                getUser: {
                    ...state.getUser,
                    loading: false,
                    message: payload.message,
                    data: payload.data,
                    error: null
                }
            };
        case GET_USER_FAILURE:
            return {
                ...state,
                getUser: {
                    ...state.getUser,
                    loading: false,
                    error: payload,
                }
            };

        case UPDATE_USER:
            return {
                ...state,
                updateUser: {
                    ...state.updateUser,
                    loading: true,
                }
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateUser: {
                    ...state.updateUser,
                    loading: false,
                    data: payload.data,
                }
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                updateUser: {
                    ...state.updateUser,
                    loading: false,
                    error: payload,
                }
            };


            case UPDATE_PASSWORD:
                return {
                    ...state,
                    updatePassword: {
                        ...state.updatePassword,
                        loading: true,
                    }
                };
            case UPDATE_PASSWORD_SUCCESS:
                return {
                    ...state,
                    updatePassword: {
                        ...state.updatePassword,
                        loading: false,
                        data: payload.data,
                    }
                };
            case UPDATE_PASSWORD_FAILURE:
                return {
                    ...state,
                    updatePassword: {
                        ...state.updatePassword,
                        loading: false,
                        error: payload,
                    }
                };
    
        default:
            return state;
    }
}
export default userReducer
