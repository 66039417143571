import {CREATE_AUDIO_STUDIO_FAILURE, CREATE_AUDIO_STUDIO_SUCCESS, CREATE_AUDIO_STUDIO, GET_CHAPTER, GET_CHAPTER_SUCCESS, GET_CHAPTER_FAILURE, GET_CHAPTER_PROMPT, GET_CHAPTER_PROMPT_SUCCESS, GET_CHAPTER_PROMPT_FAILURE, GET_AUDIO_STUDIO, GET_AUDIO_STUDIO_SUCCESS, GET_AUDIO_STUDIO_FAILURE, SET_CHAPTER_PROMPT, SET_CHAPTER_PROMPT_SUCCESS, SET_CHAPTER_PROMPT_FAILURE, GET_RECORDINGS, GET_RECORDINGS_SUCCESS, GET_RECORDINGS_FAILURE, GET_METADATA_CSV, GET_METADATA_CSV_FAILURE, GET_METADATA_CSV_SUCCESS, GET_GENERATED_VOICES, GET_GENERATED_VOICES_FAILURE, GET_GENERATED_VOICES_SUCCESS, SET_CHAPTER_PROMPT_MESSAGE, REMOVE_METADATA_CSV, TEXT_TO_SPEECH, TEXT_TO_SPEECH_SUCCESS, TEXT_TO_SPEECH_FAILURE, DELETE_GENERATED_VOICE, DELETE_GENERATED_VOICE_SUCCESS, DELETE_GENERATED_VOICE_FAILURE, GET_LEVEL, GET_LEVEL_SUCCESS, GET_LEVEL_FAILURE, SET_LEVEL, GET_TOPICS_PAGE, GET_TOPICS_PAGE_SUCCESS, GET_TOPICS_PAGE_FAILURE } from "../actonTypes";
import { getRecordings, getRecordingsPage, setChapterPrompt, textToSpeech } from "./action";
const initial_state = {
    createAudioStudio:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    textToSpeech:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getChapter:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getAudioStudio:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getRecordings:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getRecordingsPage:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getChapterPrompt:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    setChapterPrompt:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    level:0,
    getMetaDataCSV:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getGeneratedVoices:{
        data:null,
        message: "",
        error: "",
        loading: false,
    },
    getLevel:{
        data:null,
        message: "",
        error: "",
        loading: false,
    },
    deleteGeneratedVoice:{
        data:null,
        message: "",
        error: "",
        loading: false,
    },

    
}
const audioReducer = (state = initial_state, { type, payload }) => {
    console.log("payload",payload)
    switch (type) {
        case CREATE_AUDIO_STUDIO:
            return {
                ...state,
                createAudioStudio: {
                    ...state.createAudioStudio,
                    loading: true,
                }
            };
        case CREATE_AUDIO_STUDIO_SUCCESS:
            console.log("GET_USER_DETAILS_SUCCESS",payload.data)
            return {
                ...state,
                createAudioStudio: {
                    ...state.createAudioStudio,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case CREATE_AUDIO_STUDIO_FAILURE:
            return {
                ...state,
                createAudioStudio: {
                    ...state.createAudioStudio,
                    loading: false,
                    error: payload,
                }
            };
        
        case GET_AUDIO_STUDIO:
            return {
                ...state,
                getAudioStudio: {
                    ...state.getAudioStudio,
                    loading: true,
                }
            };
        case GET_AUDIO_STUDIO_SUCCESS:
            console.log("GET_AUDIO_STUDIO_SUCCESS",payload.data)
            return {
                ...state,
                getAudioStudio: {
                    ...state.getAudioStudio,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_AUDIO_STUDIO_FAILURE:
            return {
                ...state,
                getAudioStudio: {
                    ...state.getAudioStudio,
                    loading: false,
                    error: payload,
                }
            };
        
        case GET_CHAPTER:
            return {
                ...state,
                getChapter: {
                    ...state.getChapter,
                    loading: true,
                }
            };
        case GET_CHAPTER_SUCCESS:
            console.log("GET_USER_DETAILS_SUCCESS",payload.data)
            return {
                ...state,
                getChapter: {
                    ...state.getChapter,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_CHAPTER_FAILURE:
            return {
                ...state,
                getChapter: {
                    ...state.getChapter,
                    loading: false,
                    error: payload,
                }
            };
        case GET_CHAPTER_PROMPT:
            return {
                ...state,
                getChapterPrompt: {
                    ...state.getChapterPrompt,
                    loading: true,
                }
            };
        case GET_CHAPTER_PROMPT_SUCCESS:
            console.log("GET_USER_DETAILS_SUCCESS",payload.data)
            return {
                ...state,
                getChapterPrompt: {
                    ...state.getChapterPrompt,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_CHAPTER_PROMPT_FAILURE:
            return {
                ...state,
                getChapterPrompt: {
                    ...state.getChapterPrompt,
                    loading: false,
                    error: payload,
                }
            };    
    
        case SET_CHAPTER_PROMPT:
                return {
                    ...state,
                    setChapterPrompt: {
                        ...state.setChapterPrompt,
                        loading: true,
                    }
                };    
        case SET_CHAPTER_PROMPT_SUCCESS:
                console.log("GET_USER_DETAILS_SUCCESS",payload.data)
                return {
                    ...state,
                    setChapterPrompt: {
                        ...state.setChapterPrompt,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };    
        case SET_CHAPTER_PROMPT_FAILURE:
                return {
                    ...state,
                    setChapterPrompt: {
                        ...state.setChapterPrompt,
                        loading: false,
                        error: payload,
                    }
                };  
                case SET_CHAPTER_PROMPT_MESSAGE:
                return {
                    ...state,
                    setChapterPrompt: {
                        ...state.setChapterPrompt,
                        message:null
                        
                    }
                };       
        

                 case GET_RECORDINGS:
            return {
                ...state,
                getRecordings: {
                    ...state.getRecordings,
                    loading: true,
                }
            };
        case GET_RECORDINGS_SUCCESS:
            console.log("GET_AUDIO_RECORDINGS_SUCCESS",payload.data)
            return {
                ...state,
                getRecordings: {
                    ...state.getRecordings,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_RECORDINGS_FAILURE:
            return {
                ...state,
                getRecordings: {
                    ...state.getRecordings,
                    loading: false,
                    error: payload,
                }
            };


            case GET_METADATA_CSV:
                return {
                    ...state,
                    getMetaDataCSV: {
                        ...state.getMetaDataCSV,
                        loading: true,
                    }
                };
            case GET_METADATA_CSV_SUCCESS:
                console.log("GET_AUDIO_STUDIO_SUCCESS",payload.data)
                return {
                    ...state,
                    getMetaDataCSV: {
                        ...state.getMetaDataCSV,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };
            case GET_METADATA_CSV_FAILURE:
                return {
                    ...state,
                    getMetaDataCSV: {
                        ...state.getMetaDataCSV,
                        loading: false,
                        error: payload,
                    }
                };
       
                case REMOVE_METADATA_CSV:
                    return {
                        ...state,
                        getMetaDataCSV: {
                            ...state.getMetaDataCSV,
                            data:null
                        }
                    };
           
                
                
            case GET_GENERATED_VOICES:
                return {
                    ...state,
                    getGeneratedVoices: {
                        ...state.getGeneratedVoices,
                        loading: true,
                    }
                };
            case GET_GENERATED_VOICES_SUCCESS:
                console.log("GET_AUDIO_STUDIO_SUCCESS",payload.data)
                return {
                    ...state,
                    getGeneratedVoices: {
                        ...state.getGeneratedVoices,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };
            case GET_GENERATED_VOICES_FAILURE:
                return {
                    ...state,
                    getGeneratedVoices: {
                        ...state.getGeneratedVoices,
                        loading: false,
                        error: payload,
                    }
                };
               
               
               
               
                case DELETE_GENERATED_VOICE:
                    return {
                        ...state,
                        deleteGeneratedVoice: {
                            ...state.deleteGeneratedVoice,
                            loading: true,
                        }
                    };
                case DELETE_GENERATED_VOICE_SUCCESS:
                    console.log("GET_AUDIO_STUDIO_SUCCESS",payload.data)
                    return {
                        ...state,
                        deleteGeneratedVoice: {
                            ...state.deleteGeneratedVoice,
                            loading: false,
                            message: payload.message,
                            data:payload.data,
                            error: null
                        }
                    };
                case DELETE_GENERATED_VOICE_FAILURE:
                    return {
                        ...state,
                        deleteGeneratedVoice: {
                            ...state.deleteGeneratedVoice,
                            loading: false,
                            error: payload,
                        }
                    };
        
                case TEXT_TO_SPEECH:
                    return {
                        ...state,
                        textToSpeech: {
                            ...state.textToSpeech,
                            loading: true,
                        }
                    };
                case TEXT_TO_SPEECH_SUCCESS:
                    console.log("GET_USER_DETAILS_SUCCESS",payload.data)
                    return {
                        ...state,
                        textToSpeech: {
                            ...state.textToSpeech,
                            loading: false,
                            message: payload.message,
                            data:payload.data,
                            error: null
                        }
                    };
                case TEXT_TO_SPEECH_FAILURE:
                    return {
                        ...state,
                        textToSpeech: {
                            ...state.textToSpeech,
                            loading: false,
                            error: payload,
                        }
                    };
                


            case GET_LEVEL:
                return {
                    ...state,
                    getLevel: {
                        ...state.getLevel,
                        loading: true,
                    }
                };
            case GET_LEVEL_SUCCESS:
                console.log("GET_LEVEl_SUCCESS",payload.data)
                return {
                    ...state,
                    getLevel: {
                        ...state.getLevel,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };
            case GET_LEVEL_FAILURE:
                return {
                    ...state,
                    getLevel: {
                        ...state.getLevel,
                        loading: false,
                        error: payload,
                    }
                };
               
            case SET_LEVEL:
            return {
                ...state,
               level:payload
            };
               





            case GET_TOPICS_PAGE:
                return {
                    ...state,
                    getRecordingsPage: {
                        ...state.getRecordingsPage,
                        loading: true,
                    }
                };
            case GET_TOPICS_PAGE_SUCCESS:
                console.log("GET_TOPICS_PAGE_SUCCESS",payload.data)
                return {
                    ...state,
                    getRecordingsPage: {
                        ...state.getRecordingsPage,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };
            case GET_TOPICS_PAGE_FAILURE:
                return {
                    ...state,
                    getRecordingsPage: {
                        ...state.getRecordingsPage,
                        loading: false,
                        error: payload,
                    }
                };
    
        default:
                return state;
        }
}
export default audioReducer
       