export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const SOCIAL_SIGNUP_LOGIN = 'SOCIAL_SIGNUP_LOGIN'
export const SOCIAL_SIGNUP_LOGIN_SUCCESS = 'SOCIAL_SIGNUP_LOGIN_SUCCESS'
export const SOCIAL_SIGNUP_LOGIN_FAILURE = 'SOCIAL_SIGNUP_LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const SET_STATUS_NULL = 'SET_STATUS_NULL'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const OTP_VERIFICATION = 'OTP_VERIFICATION'
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS'
export const OTP_VERIFICATION_FAILURE = 'OTP_VERIFICATION_FAILURE'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const UPLOAD_AUDIO = 'UPLOAD_AUDIO'
export const UPLOAD_AUDIO_SUCCESS = 'UPLOAD_AUDIO_SUCCESS'
export const UPLOAD_AUDIO_FAILURE = 'UPLOAD_AUDIO_FAILURE'

export const UPLOAD_CSV = 'UPLOAD_CSV'
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS'
export const UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE'

export const EMPTY = 'EMPTY'
export const EMPTY_SUCCESS = 'EMPTY_SUCCESS'
export const EMPTY_FAILURE = 'EMPTY_FAILURE'

export const GET_LANGUAGE = 'GET_LANGUAGE'
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS'
export const GET_LANGUAGE_FAILURE = 'GET_LANGUAGE_FAILURE'

export const CREATE_AUDIO_STUDIO = 'CREATE_AUDIO_STUDIO'
export const CREATE_AUDIO_STUDIO_SUCCESS = 'CREATE_AUDIO_STUDIO_SUCCESS'
export const CREATE_AUDIO_STUDIO_FAILURE = 'CREATE_AUDIO_STUDIO_FAILURE'

export const GET_AUDIO_STUDIO = 'GET_AUDIO_STUDIO'
export const GET_AUDIO_STUDIO_SUCCESS = 'GET_AUDIO_STUDIO_SUCCESS'
export const GET_AUDIO_STUDIO_FAILURE = 'GET_AUDIO_STUDIO_FAILURE'

export const GET_CHAPTER = 'GET_CHAPTER'
export const GET_CHAPTER_SUCCESS = 'GET_CHAPTER_SUCCESS'
export const GET_CHAPTER_FAILURE = 'GET_CHAPTER_FAILURE'

export const GET_CHAPTER_PROMPT = 'GET_CHAPTER_PROMPT'
export const GET_CHAPTER_PROMPT_SUCCESS = 'GET_CHAPTER_PROMPT_SUCCESS'
export const GET_CHAPTER_PROMPT_FAILURE = 'GET_CHAPTER_PROMPT_FAILURE'

export const SET_CHAPTER_PROMPT = 'SET_CHAPTER_PROMPT'
export const SET_CHAPTER_PROMPT_SUCCESS = 'SET_CHAPTER_PROMPT_SUCCESS'
export const SET_CHAPTER_PROMPT_FAILURE = 'SET_CHAPTER_PROMPT_FAILURE'

export const GET_VIDEO_PROMPT = 'GET_VIDEO_PROMPT'
export const GET_VIDEO_PROMPT_SUCCESS = 'GET_VIDEO_PROMPT_SUCCESS'
export const GET_VIDEO_PROMPT_FAILURE = 'GET_VIDEO_PROMPT_FAILURE'

export const SET_VIDEO_PROMPT = 'SET_VIDEO_PROMPT'
export const SET_VIDEO_PROMPT_SUCCESS = 'SET_VIDEO_PROMPT_SUCCESS'
export const SET_VIDEO_PROMPT_FAILURE = 'SET_VIDEO_PROMPT_FAILURE'

export const GET_RECORDINGS = 'GET_RECORDINGS'
export const GET_RECORDINGS_SUCCESS = 'GET_RECORDINGS_SUCCESS'
export const GET_RECORDINGS_FAILURE = 'GET_RECORDINGS_FAILURE'

export const RESEND_OTP = 'RESEND_OTP'
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS'
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE'
export const RESEND_OTP_RESET = 'RESEND_OTP_RESET'


export const GET_METADATA_CSV = 'GET_METADATA_CSV'
export const GET_METADATA_CSV_SUCCESS = 'GET_METADATA_CSV_SUCCESS'
export const GET_METADATA_CSV_FAILURE = 'GET_METADATA_CSV_FAILURE'

export const GET_CLONE_LANGUAGE = 'GET_CLONE_LANGUAGE'
export const GET_CLONE_LANGUAGE_SUCCESS = 'GET_CLONE_LANGUAGE_SUCCESS'
export const GET_CLONE_LANGUAGE_FAILURE = 'GET_CLONE_LANGUAGE_FAILURE'

export const GET_GENERATED_VOICES = 'GET_GENERATED_VOICES'
export const GET_GENERATED_VOICES_SUCCESS = 'GET_GENERATED_VOICES_SUCCESS'
export const GET_GENERATED_VOICES_FAILURE = 'GET_GENERATED_VOICES_FAILURE'

export const SET_CHAPTER_PROMPT_MESSAGE='SET_CHAPTER_PROMPT_MESSAGE'

export const REMOVE_METADATA_CSV='REMOVE_METADATA_CSV'

export const GENERATE_VOICE_CLONE='GENERATE_VOICE_CLONE'
export const GENERATE_VOICE_CLONE_SUCCESS='GENERATE_VOICE_CLONE_SUCCESS'
export const GENERATE_VOICE_CLONE_FAILURE='GENERATE_VOICE_CLONE_FAILURE'
export const CLEAR_VIDEO_PROMPT='CLEAR_VIDEO_PROMPT'


export const TEXT_TO_SPEECH='TEXT_TO_SPEECH'
export const TEXT_TO_SPEECH_SUCCESS='TEXT_TO_SPEECH_SUCCESS'
export const TEXT_TO_SPEECH_FAILURE='TEXT_TO_SPEECH_FAILURE'

export const DELETE_GENERATED_VOICE = 'DELETE_GENERATED_VOICE'
export const DELETE_GENERATED_VOICE_SUCCESS = 'DELETE_GENERATED_VOICE_SUCCESS'
export const DELETE_GENERATED_VOICE_FAILURE = 'DELETE_GENERATED_VOICE_FAILURE'

export const All_USER_GET='All_USER_GET'
export const All_USER_GET_SUCCESS='All_USER_GET_SUCCESS'
export const All_USER_GET_FAILURE='All_USER_GET_FAILURE'

export const TESTING_AUTH_TOKEN='TESTING_AUTH_TOKEN'
export const TESTING_AUTH_TOKEN_SUCCESS='TESTING_AUTH_TOKEN_SUCCESS'
export const TESTING_AUTH_TOKEN_FAILURE='TESTING_AUTH_TOKEN_FAILURE'

export const GET_LEVEL = 'GET_LEVEL'
export const GET_LEVEL_SUCCESS = 'GET_LEVEL_SUCCESS'
export const GET_LEVEL_FAILURE = 'GET_LEVEL_FAILURE'

export const SET_LEVEL = 'SET_LEVEL'


export const GET_TOPICS_PAGE = 'GET_TOPICS_PAGE'
export const GET_TOPICS_PAGE_SUCCESS = 'GET_TOPICS_PAGE_SUCCESS'
export const GET_TOPICS_PAGE_FAILURE = 'GET_TOPICS_PAGE_FAILURE'



