import { call, put, takeLatest } from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from "react-toastify";
import { GET_USER, GET_USER_FAILURE, GET_USER_SUCCESS, LOGOUT, UPDATE_PASSWORD, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from "../actonTypes";

function* watcherGetUser() {
    let url = '/Profile_controller/userGet';
    const Data = yield call(callApi, url, 'GET', '', true);
    console.log("..................................", Data.data)
    if (Data.data.status === "true") {
        yield put({ type: GET_USER_SUCCESS, payload: Data.data });
    }
    else {
        console.log("LOGOUT 22222222222 :", Data.data.status);
        yield put({ type: LOGOUT })
        yield put({ type: GET_USER_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}

function* watcherUpdateUser(data) {
    let toastId = toast.loading("Loading...")
    let url = '/Profile_controller/editProfile';
    const Data = yield call(callApi, url, 'POST', data.payload, true);
    if (Data.status) {
        toast.update(toastId, { render: Data.data.message, type: 'success', isLoading: false, autoClose: 1000 })
        yield put({ type: UPDATE_USER_SUCCESS, payload: Data.data });
        yield put({ type: GET_USER });
    }
    else {
        toast.update(toastId, { render: Data.data.message, type: 'error', isLoading: false, autoClose: 1000 })
        yield put({ type: UPDATE_USER_FAILURE, payload: Data.data.error })
    }
}


function* watcherUpdatePassword(data) {
    let toastId = toast.loading("Loading...")
    let url = '/Profile_controller/editPassword';
    const Data = yield call(callApi, url, 'POST', data.payload, true);
    if (Data.status) {
        toast.update(toastId, { render: Data.data.message, type: 'success', isLoading: false, autoClose: 1000 })
        yield put({ type: UPDATE_PASSWORD_SUCCESS, payload: Data.data });
        // yield put({ type: GET_USER });
    }
    else {
        toast.update(toastId, { render: Data.data.message, type: 'error', isLoading: false, autoClose: 1000 })
        yield put({ type: UPDATE_PASSWORD_FAILURE, payload: Data.data.error })
    }
}

export default function* watchUser() {
    yield takeLatest(GET_USER, watcherGetUser)
    yield takeLatest(UPDATE_USER, watcherUpdateUser)
    yield takeLatest(UPDATE_PASSWORD, watcherUpdatePassword)

}