import React, { useEffect, useState } from 'react';
import AudioItem from './AudioItem';
import { useDispatch, useSelector } from 'react-redux';
import { getRecordingsPage } from '../../redux/audio/action';

const VoicesWithPagination = ({ id, filterId, addVoices }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const recordingsInPage = useSelector((state) => state?.audioReducer?.getRecordingsPage?.data);
    const loading = useSelector((state) => state?.audioReducer?.getRecordingsPage?.loading);

    const dispatch = useDispatch();

    // Calculate total pages
    useEffect(() => {
        if (recordingsInPage?.pagination && addVoices) {
            const pages = Number(addVoices) / recordingsInPage.pagination.topics_per_page;
            setTotalPage(Math.ceil(pages)); // Round up to the nearest whole number
        }
    }, [addVoices, recordingsInPage]);

    console.log("totalPage", totalPage); // Debugging

    // Fetch recordings based on current page
    useEffect(() => {
        if (filterId && id) {
            dispatch(getRecordingsPage({
                page: currentPage,
                chapter_id: filterId,
                language_id: id
            }));
        }
    }, [filterId, id, currentPage, dispatch]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const maxVisiblePages = 3; // Number of pages to show
    const halfVisible = Math.floor(maxVisiblePages / 2);

    const getVisiblePages = () => {
        const start = Math.max(1, currentPage - halfVisible);
        const end = Math.min(totalPage, start + maxVisiblePages - 1);

        const visiblePages = [];
        for (let i = start; i <= end; i++) {
            visiblePages.push(i);
        }
        return visiblePages;
    };


    const visiblePages = getVisiblePages();

    return (
        <>
        {
            loading?
            <>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 mt-4">
 
 <div className="bg-gray-200 rounded-lg p-4 animate-pulse">

     <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
     <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
 </div>
 <div className="bg-gray-200 rounded-lg p-4 animate-pulse">

<div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
<div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
</div>
 <div className="bg-gray-200 rounded-lg p-4 animate-pulse">
    
     <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
     <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
 </div>
 <div className="bg-gray-200 rounded-lg p-4 animate-pulse">
  
     <div className="h-4 bg-gray-300 rounded w-3/4 mb-1"></div> {/* Placeholder for title */}
     <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for additional info */}
                     </div>

                     </div>
            </>:
             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 mt-4">
             {recordingsInPage?.topics?.map((audio, index) => (
                 <div key={index}>
                     <AudioItem {...audio} chapter={filterId} index={index + 1} />
                 </div>
             ))}
         </div>
        }
           

<hr className='w-full my-2' />
            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    Previous
                </button>

                {visiblePages.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`px-4 py-2 ${currentPage === page ? 'bg-colorPrimary text-white' : 'bg-gray-200'}`}
                    >
                        {page}
                    </button>
                ))}

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPage}
                    className={`px-4 py-2 ${currentPage === totalPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default VoicesWithPagination;
