import React, { useEffect, useState } from 'react'
import LoginForm from "./LoginForm";
// import logo from '../../../assets/svgs/logo.svg'
import logo from '../../../assets/svgs/dipspik.svg'

// import logo from "../../../assets/images/dipspik.png";
import OtpScreen from '../../../components/ui/OtpScreen';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export default function Login() {
  const isAuth = useSelector((state) => state?.authReducer?.isAuthenticated);
  useEffect(() => {
    if (isAuth) {
      navigate('/home');
    }
  }, [isAuth]);
  const [screenStep, setScreenStep] = useState("login")
  const isEmail = localStorage.getItem("email")
  useEffect(() => {
    if (isEmail && screenStep === "otp") return;
    isEmail ?
      setScreenStep("otp") :
      setScreenStep("login")
  }, [isEmail])
  console.log("isEmail: ", isEmail, screenStep);
    const navigate=useNavigate()

  return (
    <div className="flex flex-col lg:justify-betwee justify-cente w-full h-screen">
      <div className='flex justify-between items-center'>
        <img  src={logo} alt="logo" className="ml-6 my-5 h-fit " />
        {/* <button className='mr-6' onClick={()=>navigate('/admin')}>Go to Admin Page</button> */}
      </div>
      {/* {screenStep === 'login' && <LoginForm setScreenStep={setScreenStep} />} */}
      {/* {screenStep === 'otp' && <OtpScreen setScreenStep={setScreenStep} />} */}

      <LoginForm setScreenStep={setScreenStep} />



    </div>
  )
}
