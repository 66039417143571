import React, { useState } from 'react';
import InputField from '../../components/ui/InputField';
import { useDispatch } from 'react-redux';
import { updatePassword } from '../../redux/user/action';

const PasswordModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        old_password: "",
        new_password: "",
    });

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleValues = (value, type) => {
        setData(prevState => ({
            ...prevState,
            [type]: value
        }));
    };
const dispatch=useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic for handling password change

        dispatch(updatePassword(data))
        console.log("Submitting data:", data);
        // Call an API or dispatch an action to change the password
        // After successful submission, reset data and close modal
        setData({
            old_password: "",
            new_password: "",
        });


        setShowModal(false);
    };

    return (
        <>
            <button onClick={() => setShowModal(true)} className='flex items-center justify-center gap-1.5 bg-colorPrimary text-white px-4 py-2 rounded-lg text-sm w-full md:w-auto mt-2 md:mt-0'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* Your SVG code here */}
                </svg>
                <span>Change Password</span>
            </button>

            {showModal && (
                <>
                    <div className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative md:w-1/2 w-auto my-6 mx-auto max-w-3xl">
                            {/* Content */}
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* Header */}
                                <div className="flex items-start rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                    <button
                                        className="border-2 px-[5px] border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent text-yellowPrimary float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={handleCancel}
                                    >
                                        ×
                                    </button>
                                </div>
                                {/* Body */}
                                <div className="relative p-6 pb-5 pt-0 flex-auto">
                                    <form onSubmit={handleSubmit}>
                                        <InputField
                                            label={"Old Password"}
                                            type={"password"}
                                            value={data.old_password}
                                            onChange={(value) => handleValues(value, 'old_password')}
                                        />
                                        <InputField
                                            label={"New Password"}
                                            type={"password"}
                                            value={data.new_password}
                                            onChange={(value) => handleValues(value, 'new_password')}
                                        />
                                        <button type="submit" className="bg-colorPrimary text-white px-4 py-2 rounded-lg mt-4">
                                            Change Password
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
}

export default PasswordModal;
