import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { allUserGet, testingAuthToken } from "../../../redux/auth/action";
import { select } from "redux-saga/effects";



const AdminTable = () => {
  const dispatch = useDispatch();

    const columns = [
        {
            name:"ID",
            selector:(row)=>row.id
        },
        {
            name:"Name",
            selector:(row)=>row.name
        },
        
        {
          name: "Email",
          selector: (row) => row.email,
        },
        {
          name: "Phone",
          selector: (row) => row.phone,
        },
        {
          name: "Action",
          selector: (row) => (
            <button onClick={()=>dispatch(testingAuthToken({id:row?.id, email:row?.email}))} className="border border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg hover:bg-colorPrimary hover:text-white">
              Login
            </button>
          ),
        },
        
      ];
  const allUsers = useSelector((state) => state?.authReducer?.allUser?.data);
  
  const [searchQuery, setSearchQuery] = useState("");
  
  useEffect(() => {
    dispatch(allUserGet());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = allUsers?.filter((user) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      user.email?.toLowerCase().includes(lowerCaseQuery) ||
      user.phone?.includes(searchQuery) ||
      user.id?.toString().includes(searchQuery)
    );
  }) || [];
  

  return (
    <div className="p-4">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by id, email or phone number"
          value={searchQuery}
          onChange={handleSearchChange}
          className="border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
      </div>
      <DataTable columns={columns} data={filteredData} />
    </div>
  );
};

export default AdminTable;
