import { CREATE_AUDIO_STUDIO, DELETE_GENERATED_VOICE, GENERATE_VOICE_CLONE, GET_AUDIO_STUDIO, GET_CHAPTER, GET_CHAPTER_PROMPT, GET_GENERATED_VOICES, GET_LEVEL, GET_METADATA_CSV, GET_RECORDINGS, GET_TOPICS_PAGE, REMOVE_METADATA_CSV, SET_CHAPTER_PROMPT, SET_CHAPTER_PROMPT_MESSAGE, SET_LEVEL, TEXT_TO_SPEECH} from "../actonTypes"
export const createAudioStudio= (data) => {
    return ({
        type: CREATE_AUDIO_STUDIO,
        payload:data
    })
}
export const getChapter= (data) => {
    return ({
        type: GET_CHAPTER,
        payload:data
    })
}
export const getChapterPrompt= (data) => {
    return ({
        type: GET_CHAPTER_PROMPT,
        payload:data
    })
}
export const setChapterPrompt= (data) => {
    return ({
        type: SET_CHAPTER_PROMPT,
        payload:data
    })
}
export const getAudioStudio= () => {
    return ({
        type: GET_AUDIO_STUDIO,
    })
}
export const getRecordings= (data) => {
    return ({
        type: GET_RECORDINGS,
        payload:data  
    })
}

export const getMetaDataCSV= (data) => {
    return ({
        type: GET_METADATA_CSV,
        payload:data  
    })
}

export const removeMetaDataCSV= () => {
    return ({
        type: REMOVE_METADATA_CSV,
       
    })
}

export const setChapterPromptMessage= () => {
    return ({
        type: SET_CHAPTER_PROMPT_MESSAGE,
    })
}
export const getGeneratedVoices= (data) => {
    return ({
        type: GET_GENERATED_VOICES,
        payload:data  
    })
}
export const generateVoiceClone= (data) => {
    return ({
        type: GENERATE_VOICE_CLONE,
        payload:data  
    })
}

export const textToSpeech= (data) => {
    return ({
        type: TEXT_TO_SPEECH,
        payload:data  
    })
}

export const deleteGeneratedVoice= (data) => {
    return ({
        type: DELETE_GENERATED_VOICE,
        payload:data  
    })
}

export const getLevel= (data) => {
    return ({
        type: GET_LEVEL,
        payload:data  
    })
}

export const setLevel= (data) => {
    return ({
        type: SET_LEVEL,
        payload:data
    })
}

export const getRecordingsPage= (data) => {
    return ({
        type: GET_TOPICS_PAGE,
        payload:data
    })
}