import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
const truncateText = (text, wordLimit) => {
    const words = text?.split(' ');
    if (words?.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const decodeURL = (url) => {
    return decodeURIComponent(url);
};

const AudioItem = ({ title,topic_id, prompt,audio,chapter}) => {
    const data = useSelector((state) => state.userReducer?.getUser?.data)
    const {name } = useParams();
    const encodedName = name?.replace(/\s/g, '');
    const decodedURL = decodeURL(`https://dipspik.com/soulcast/public/uploads/${data?.id}/recordings/${encodedName}/wav/${chapter}/${audio}`);
    const truncatedPrompt = truncateText(prompt, 6);
    console.log(`https://dipspik.com/soulcast/public/uploads/${data?.id}/recordings/${encodedName}/${chapter}/${topic_id}/${audio}`)
    return (
        <div className={`bg-gray-100 rounded-lg p-3 flex flex-col gap-3 mx-3 mb-6`}>
            <div className="flex w-full">
                <p className='text-sm w-full'>{truncatedPrompt}</p>
            </div>
            <div className="flex gap-2 text-sm justify-end text-gray-400 w-full">
                <audio className='w-full' controls src={decodedURL} preload="none" ></audio>
            </div>
        </div>
    )
}

export default AudioItem