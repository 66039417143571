import React from 'react'

const ChapterLoader = () => {
  return (
   
    <div className='flex md:flex-col gap-2 items-center  md:w-full overflow-x-auto p-4'>
    <button className="border border-gray-300 w-full p-3 rounded-lg animate-pulse">
        <div className="flex flex-col md:flex-row gap-2 items-center my-2 justify-between">
            <div className="flex items-center gap-2">
                <div className="bg-gray-300 rounded-full w-5 h-5"></div>
                <div className="bg-gray-300 h-4 w-24 md:w-1/3 rounded"></div>
            </div>
            <div className="bg-gray-300 py-1 px-3 rounded-full text-sm w-20"></div>
        </div>

        <div className="w-full bg-white rounded-full">
            <div className="bg-gray-300 h-2 rounded-full"></div>
        </div>

        <div className="flex justify-end items-center text-sm mt-2">
            <div className="bg-gray-300 h-4 w-1/4 rounded"></div>
        </div>
    </button>
</div>


  )
}

export default ChapterLoader