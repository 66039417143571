import React, { useEffect, useState } from 'react'
import InputField from '../../../components/ui/InputField';
import { NavLink } from 'react-router-dom';
import loader from '../../../assets/svgs/loader.svg'
import ThirdPartySignin from '../../../components/ui/ThirdPartySignin';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/auth/action';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
export default function LoginForm({ setScreenStep }) {
    const [value, setValue] = useState()
    const response = useSelector((state) => state?.authReducer)

    const loading = useSelector((state) => state?.authReducer?.login?.loading)
    const load = true;
    useEffect(() => {
        if (response?.login?.status === "verify") {
            setScreenStep("otp");
        }

    }, [response])



    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: "",
        password: "",
        type: "CUSTOM",
    })
    const handleValues = (value, type) => {
        console.log(value, type);
        if (type === 'email') {
            setData(prevState => ({
                ...prevState,
                email: value
            }))
        }
        if (type === 'password') {
            setData(prevState => ({
                ...prevState,
                password: `${value}`  // Ensure the phone number starts with '+'
            }))
        }
        console.log(data);
    }
    const handelSubmit = () => {
        localStorage.setItem('email', data.email)
        // localStorage.setItem('otpPhone', data.phone)
        dispatch(login(data))

    }
    return (
        <div className="flex flex-col sm:w-[406px] mx-5 sm:mx-auto grow">
            <div className="flex flex-col gap-3 my-auto">
                <div className="mb-3">
                    <h1 className="text-colorPrimary text-2xl font-semibold text-center">Welcome Back!</h1>
                    <p class="text-gray-500 text-center mt-1.5">Enter your credentials to Login</p>
                </div>
                <InputField label={"Enter Email"} type={"email"} value={data?.email} onChange={handleValues} />
                {/* <InputField label={"Enter Phone Number"} type={"phone"} value={data?.phone} onChange={handleValues} />
                 */}
                <InputField label={"Enter Password"} type={"password"} value={data?.password} onChange={handleValues} />
                {/* <div className='flex flex-col'>
                    <label htmlFor="phone" className='text-sm font-semibold mb-1'>Phone Number</label>
                    <PhoneInput
                        country={"pk"}
                        enableSearch={true}
                        value={data.phone}
                        onChange={(phone) => handleValues(phone, 'phone')}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                            // containerClass: `block w-full rounded-md border-0 pl-2.5 pr-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-colorPrimary sm:text-sm sm:leading-6 focus:outline-none`
                        }}
                    />
                </div> */}
                <div>
                    <button type="submit" onClick={handelSubmit} className={`flex w-full justify-center rounded-md ${loading ? 'bg-gray-100 hover:bg-white py-0 ' : 'bg-colorPrimary py-1.5 '}  px-3   text-sm font-semibold leading-6 text-white shadow-sm hover:bg-colorPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-colorPrimary`}>{loading ? <img className='w-10' src={loader} alt="" /> : 'Log in'}</button>
                </div>
                <div className="flex items-center gap-2.5">
                    <hr className='w-full' />
                    <p className='text-gray-500'>or</p>
                    <hr className='w-full' />
                </div>
                <ThirdPartySignin type="Log in" />
                <p className="mt-3 text-center text-sm text-gray-500">
                    Already have an account?{' '}
                    <NavLink to="/signup" className="font-semibold leading-6 text-colorPrimary">
                        Sign Up
                    </NavLink>
                </p>
            </div>
        </div>
    )
}