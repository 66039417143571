import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../components/ui/InputField';
import frameSvg from '../../assets/svgs/imageFrameSvg.svg';
import { uploadFile } from '../../redux/uploadFile/action';
import { toast } from 'react-toastify';
import { updateUser } from '../../redux/user/action';
import PasswordModal from './PasswordModal';

const UpdateUserProfile = () => {
    const picInpRef = useRef();
    const dispatch = useDispatch();
    const [data, setData] = useState({ profile_picture: "", name: "", bio: "" })
    const user = useSelector((state) => state.userReducer?.getUser?.data)
    const file = useSelector((state) => state?.uploadFileReducer?.uploadFile?.data)

    const handleValues = (value, type) => {
        setData({ ...data, [type]: value })
        console.log(data);
    }

    const handleFile = (e) => {
        const file = e.target.files[0];
        dispatch(uploadFile(file));
        picInpRef.current.value = null
    }

    useEffect(() => {
        if (user) {
            const { profile_picture, name, bio } = user
            setData({ profile_picture, name, bio })
        }
    }, [user])

    useEffect(() => {
        console.log(file);
        if (file) {
            console.log(file);
            setData({ ...data, profile_picture: file })
        }
        console.log(data)
    }, [file])

    const handleCancel = () => {
        if (user) {
            const { profile_picture, name, bio } = user
            setData({ profile_picture, name, bio: bio ? bio : "" })
        }
    }
    const handleSubmit = () => {
        if (data?.name && data?.bio && data?.profile_picture) {
            console.log(data);
            dispatch(updateUser(data))
        } else {
            toast.error("All fields are required!")
        }
    }
    return (
        <>
            <div className="profile bg-gray-50 w-full pt-4 pb-6 shadow px-4 sm:px-8 rounded-lg h-fit col-span-5 md:col-span-4">
                <div className="profileCard pt-4" >
                    <div className="profileForm">
                        <div className="col-span-full flex items-center gap-4">
                            <div className="flex items-center gap-x-3">
                                {data?.profile_picture ?
                                    // <label htmlFor="profileDp" onClick={() => picInpRef.current.click()} className='text-gray-400 text-sm hover:text-colorPrimary cursor-pointer rounded-full border-2 border-colorPrimary p-6'>
                                    <img className="w-16 h-16 cursor-pointer rounded-full border-2 border-colorPrimary" src={data?.profile_picture} id="profileDp" onClick={() => picInpRef.current.click()} alt="profile dp" />
                                    // </label> 
                                    :
                                    <img className='w-16 h-16 cursor-pointer rounded-full border-2 border-colorPrimary' src={user?.profile_picture} onClick={() => picInpRef.current.click()} alt="" />
                                }
                                <input type="file" name="profileDp" id="profileDp" ref={picInpRef} className='sr-only' onChange={handleFile} />

                            </div>
                            <label for="photo" className="block text-xl font-bold leading-6 text-colorPrimary">Upload image</label>
                        </div>
                        <div className="border- border-gray-900/10 pb-6">
                            <div className="mt-7 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-2">
                                <InputField label={"Full Name"} type={"name"} value={data?.name} onChange={handleValues} />
                                <InputField label={"Email Address"} type={"email"} value={user?.email} disable={true} />
                                <InputField label={"Phone Number"} type={"phone"} value={user?.phone} disable={true} />
                                <InputField label={"Bio"} type={"bio"} value={data.bio} onChange={handleValues} />
                            </div>
                        </div>
                        <div className="mt-4 flex items-center justify-between gap-x-4">
                            <div><PasswordModal/></div>
                            <div className='flex gap-2'>
                            <button type="submit" onClick={handleCancel} className="border border-colorPrimary rounded-lg bg-white hover:bg-colorPrimary text-colorPrimary hover:text-white px-4 py-2 text-sm font-semibold shadow-sm hover:shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cpbg-colorPrimary">Cancel</button>
                            <button type="submit" onClick={handleSubmit} className="border border-colorPrimary rounded-lg bg-colorPrimary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cpbg-colorPrimary">Save Changes</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateUserProfile